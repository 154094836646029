.sc-breadcrumbs {
    text-align: left;
    &__list {
        display: inline;
    }

    &__item {
        padding: 5px 0;
        display: inline;
        align-items: center;
        line-height: 25px;

        color: $color-breadcrumbs-inactive-black;

        &:last-child {
            color: $color-breadcrumbs-active-black;
        }

        svg {
            fill: $color-breadcrumbs-inactive-black;
            margin-right: 10px;
            margin-left: 10px;
            position: relative;
            top: 4px;
        }

        @include body-small-semibold;
    }
}
