@import "variables", "media-queries", "mixins", "typography";
.btn {
    position: fixed;
    bottom: 24px;
    right: 20px;
    width: 157px;
    height: 48px;
    z-index: 20;
    font-size: 100%;
    border-radius: 5px;
    overflow: hidden;
}
