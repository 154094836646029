@import "variables", "media-queries", "mixins", "typography";
.wrapper {
    position: fixed;
    z-index: 5;
    bottom: 80px;
    left: calc(50% - 250px);
    background-image: $gradient-first;
    padding: 8px 10px;
    border: 1px solid $color-basic-border;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 15px;
    max-width: 500px;
    width: calc(100% - 30px);
    font-size: 16px;
    animation: fadeFromBottom 1s 2s forwards;
    opacity: 0;
    color: $color-text-primary;

    br {
        display: none;
    }

    a {
        border-bottom: 1px solid $color-text-primary;
    }

    @include media-breakpoint(sm) {
        border-radius: 0;
        width: 100%;
        max-width: 100%;
        left: 0;
        transform: none;
        bottom: auto;
        top: 75px;
        border-left: none;
        border-right: none;
        padding: 15px 30px;
        font-size: 14px;
        gap: 15px;
        animation: fadeFromTop 1s 2s forwards;

        br {
            display: block;
        }

        p {
            max-width: calc(100% - 70px);
        }
    }

    @include media-breakpoint(450px) {
        flex-wrap: wrap;
    }

    @include media-breakpoint(350px) {
        gap: 5px;
        p {
            max-width: 100%;
            margin-right: -20px;
        }
    }
}

.img {
    @include media-breakpoint(sm) {
        width: 30px;
        height: 30px;
    }

    @include media-breakpoint(350px) {
        width: 20px;
        height: 20px;
    }
}

.close {
    padding: 10px 28px !important;
    margin-left: auto;

    @include media-breakpoint(450px) {
        width: 100% !important;
    }
}

@keyframes fadeFromBottom {
    0% {
        opacity: 0;
        transform: translate(0, 20px);
    }
    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}

@keyframes fadeFromTop {
    0% {
        opacity: 0;
        transform: translate(0, -20px);
    }
    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}
