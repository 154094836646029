.sc-page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    &__inner {
        flex: 1;
        display: flex;
        flex-direction: column;
    }
}

.sc-container {
    width: 100%;
    max-width: $container-width + 60px;
    margin-left: auto;
    margin-right: auto;
    padding-left: $container-indent;
    padding-right: $container-indent;
    @include media-breakpoint(sm) {
        padding-left: $container-indent-sm;
        padding-right: $container-indent-sm;
    }

    &--divider-top {
        border-top: 1px solid $color-misc-hr;
    }

    &--divider-bottom {
        border-bottom: 1px solid $color-misc-hr;
    }
}
