.sc-header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 20;
    transition: all 0.3s ease-in-out;
    background-color: $color-white;
    box-shadow: 0px 2px 16px rgba(32, 108, 164, 0.16);

    .sc-header__button {
        @include media-breakpoint-min(1080px) {
            padding-left: 26px;
            padding-right: 26px;
        }

        @include media-breakpoint(xs) {
            background: transparent !important;
            box-shadow: none !important;
            padding: 0 !important;
            font-size: 0 !important;
            width: 48px !important;
            height: 48px !important;

            span {
                display: none !important;
            }
        }

        white-space: nowrap;
    }

    &.sc-header--sticky {
        .sc-header__button {
            @include media-breakpoint(xs) {
                i {
                    color: $color-text-primary !important;
                }
            }
        }
    }

    &.sc-header--color-black {
        .sc-header__button {
            @include media-breakpoint(xs) {
                i {
                    color: $color-text-primary !important;
                }
            }
        }
    }

    &__logo {
        flex-shrink: 0;
    }

    &__main {
        display: flex;
        align-items: center;
        height: 78px;
    }

    &__content {
        margin-left: auto;
        display: flex;
        align-items: center;
    }

    &__buttons {
        margin-left: 30px;
        display: grid;
        grid-gap: 15px;
        grid-template-columns: repeat(2, 1fr);

        @include media-breakpoint(xs) {
            margin-left: 0;
        }
    }

    &__burger {
        margin-left: 25px;
        display: none;

        @include media-breakpoint(md) {
            display: flex;
        }

        @include media-breakpoint(xs) {
            margin-left: auto;
        }
    }
}

.sc-header-desk-menu {
    @include media-breakpoint(md) {
        display: none;
    }

    &--sticky {
        .sc-header-desk-menu__main-link {
            color: $color-text-primary;
        }
    }

    &--black {
        .sc-header-desk-menu__main-link {
            color: $color-text-primary;
        }
    }

    &__main-list {
        display: flex;
        align-items: center;
        margin: 0;
    }

    &__main-item {
        &:not(:last-child) {
            margin-right: 30px;

            @include media-breakpoint(1080px) {
                margin-right: 10px;
            }
        }

        &.sc-header-desk-menu__main-item--current {
            .sc-header-desk-menu__main-link {
                color: $color-basic-primary !important;
            }
        }

        &:hover {
            cursor: pointer;

            .sc-header-desk-menu__main-link {
                color: $color-basic-primary;
            }
        }

        &.is-active {
            .sc-header-desk-menu__children {
                opacity: 1;
                pointer-events: auto;
                transition: opacity 0.15s 0.15s ease-in-out;
            }

            .sc-header-desk-menu__main-link {
                color: $color-basic-primary;

                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }

    &__main-link {
        display: flex;
        align-items: center;
        white-space: nowrap;
        line-height: 163%;
        padding: 22px 0;
        transition: color 0.2s;
        color: $color-white;
        font-weight: 600;

        i {
            font-size: 24px;
        }
    }

    &__children {
        opacity: 0;
        pointer-events: none;
        position: absolute;
        background-color: $color-white;
        top: calc(100% - 1px);
        left: 0;
        right: 0;
        padding: 30px 0;
        box-shadow: 0px 8px 8px rgba(32, 108, 164, 0.08);
        transition: opacity 0.1s ease-in-out;

        .sc-header-desk-menu__children-list {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-gap: 25px 30px;

            a {
                white-space: normal;
            }

            &--mod-4 {
                grid-template-columns: repeat(4, 1fr);
            }
        }

        .sc-header-desk-menu__children-item {
            a {
                display: flex;
                align-items: center;
            }

            .sc-header-desk-menu__children-item-icon {
                align-self: flex-start;
                flex-shrink: 0;
                margin-right: 10px;
                width: 54px;
                height: 54px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $color-white;
                box-shadow: 0px -8px 18px -5px #dee3ff inset;
                border-radius: 12px;
            }

            &.sc-header-desk-menu__children-item--current {
                .sc-header-desk-menu__children-link {
                    color: $color-basic-primary;
                }
            }
        }

        .sc-header-desk-menu__children-link {
            font-weight: 600;
            &:hover {
                color: $color-basic-primary;
            }
        }
    }
}
