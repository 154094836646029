@use 'styles/variables' as var;
@use 'styles/typography' as typo;

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    //word-wrap: break-word;
    //word-break: break-word;
}

html,
body {
    @include body-default;
    background-color: $color-basic-site-bg;
    padding: 0;
    margin: 0;
    color: $color-text-primary;
}

a {
    color: inherit;
    text-decoration: none;
}

em {
    font-style: normal;
}

img {
    max-width: 100%;
}

hr {
    opacity: 0.1;
}

mark {
    background-color: $color-mark-bg;
    color: $color-black;
}

.page {
    overflow-x: hidden;
}
.zopim {
    z-index: 20 !important;
}
.overflowHidden {
    overflow: hidden;
}

.sc-page-gradient-wrap {
    position: relative;

    &__img-wrap {
        overflow: hidden;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    &__img {
        position: relative;
        top: -160px;
        left: 50%;
        transform: translateX(-45%);
        width: 2800px;
        height: 1418px;
        filter: blur(10px);
    }
    &__inner {
        position: relative;
        z-index: 1;
    }
}
