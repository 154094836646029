.sc-heading {
    text-align: center;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;

    &.sc-heading--align-left {
        text-align: left;

        .title {
            text-align: left;
        }

        @include media-breakpoint(md) {
            text-align: center;

            .sc-heading__title {
                text-align: center;
            }
        }

        @include media-breakpoint(sm) {
            text-align: left;

            .sc-heading__title {
                text-align: left;
            }
            margin-left: 0;
        }
    }

    &.sc-heading--align-md-left {
        @include media-breakpoint(md) {
            text-align: left;
            max-width: 100%;

            .sc-heading__title {
                max-width: 100%;
                text-align: left;
            }
        }
    }

    @include media-breakpoint(sm) {
        text-align: left;
        margin-left: 0;

        .sc-heading__image {
            text-align: center;
        }

        .sc-heading__title {
            text-align: left;
        }
    }

    &.sc-heading--typ-promo {
        @include media-breakpoint(sm) {
            text-align: center;

            .sc-heading__title {
                text-align: center;
            }
        }
    }

    &__title {
        color: $color-text-primary;
    }

    &__subheading {
        color: $color-text-secondary;
        margin-top: 15px;
    }

    &__image {
        margin-bottom: 20px;
    }

    &__subheading {
        @include body-big;

        &.sc-heading__subheading--size-small {
            @include body-default;
        }

        &.sc-heading__subheading--size-big {
            font-size: 24px;

            @include media-breakpoint(md) {
                font-size: 20px;
            }
        }
    }

    &__content {
        > * {
            &:not(:last-child) {
                margin-bottom: 30px;
            }
        }

        a {
            color: $color-text-link;
        }
    }
}
